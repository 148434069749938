import React, { useState } from "react"

const ResultRow = props => {
  const [detail, showDetail] = useState(false)

  return (
    <>
      <button className="result-row" onClick={() => showDetail(!detail)}>
        <span>{props.chapter}</span>
        <div className="details-btn">
          {!detail ? "VIEW DETAIL" : "HIDE DETAIL"}
        </div>
        <div className="result-box">
          <p className="result_text">{props.text}</p>
          <div className={"result-bar result-" + props.score}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </button>

      <div className={"chapter-info " + (detail && "visible")}>
        <div className="chapter-info_text">
          <p>
            <strong>
              {props.bird} ({props.sound})
            </strong>
          </p>
          <p>{props.description}</p>
        </div>
        <div
          className={
            "bird " +
            props.bird
              .toLowerCase()
              .replace(/ /g, "-")
              .replace(/[^\w-]+/g, "")
          }
        ></div>
      </div>
    </>
  )
}

export default ResultRow
