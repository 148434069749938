import React, { useEffect, useState } from "react"
import Cookies from "universal-cookie"
import { Link } from "gatsby"
import ResultRow from "../components/resultRow"
import { Helmet } from "react-helmet"

const cookies = new Cookies()

const Results = () => {
  const [results, setResults] = useState([])
  const [resultText, setText] = useState("Moderate hearing loss")
  const [popup, openPopup] = useState(false)
  const [initialPopup, openInitialPopup] = useState(true)
  const [ready, setReady] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [pageLoad, pageLoaded] = useState(false)

  useEffect(() => {
    let cookieResults = []

    setTimeout(function () {
      pageLoaded(true)
    }, 500)

    cookieResults.push(
      {
        chapter: "Chapter 1: The Stream",
        bird: "Song thrush",
        sound: "3–4 kHz, High",
        description:
          "Arresting and brilliant is the song thrush in full voice. As a composer he displays a marked tendency to repeat the same phrase two or three times. He has a large repertoire (around 200 phrases) however, and enunciates his phrases with clarity and vigour.",
        result: parseInt(cookies.get("chapter1")),
      },
      {
        chapter: "Chapter 2: The Rain",
        bird: "Wren",
        sound: "4–8 kHz, Very high",
        description:
          "The wren’s song is cheerful, clear and ebullient. A schoolboy striving to complete his homework is said to have complained of “that shattering wren”. For the diminutive size of the bird it is one of the loudest of bird songs, and is also one of the highest-pitched ranging from about one full octave below to one full octave above the highest note on the piano.",
        result: parseInt(cookies.get("chapter2")),
      },
      {
        chapter: "Chapter 3: The Bird hide",
        bird: "Cuckoo",
        sound: "350–750 Hz, Medium low",
        description:
          "Males give their distinctive song to defend territories and attract mates. Females advertise with a softer version of the song. Both sexes give a lower-pitched, slower version when searching for nest sites and building the nest.",
        result: parseInt(cookies.get("chapter3")),
      },
      {
        chapter: "Chapter 4: The Wind",
        bird: "Blackbird",
        sound: "1–2 kHz, Medium",
        description:
          "The blackbird has been called the Beethoven among birds. The Blackbird sings long, beautifully shaped phrases, well-defined in time and tone. The effect is mellow, flute-like and musical.",
        result: parseInt(cookies.get("chapter4")),
      },
      {
        chapter: "Chapter 5: The Campfire",
        bird: "Collared Dove",
        sound: "250–350 Hz, Low",
        description:
          "The song (or “perch-coo”) is given mainly by unmated males from a conspicuous perch. It’s a soft coo-oo followed by two or three louder coos. You can often hear paired males give the three-parted “nest call” while nest-building: a coo-OO-oo, highest in the middle. Females sometimes call ohr ohr while sitting on the nest.",
        result: parseInt(cookies.get("chapter5")),
      }
    )

    setResults(cookieResults)

    const total = cookieResults.reduce((sum, item) => sum + item.result, 0)

    let scoredOver = cookieResults.some(value => {
      return value.result > 3
    })

    if (total > 14 || scoredOver) {
      setText(
        "After our walk through the woods, our assessment is that you may have hearing loss and could benefit from speaking with your GP or healthcare provider."
      )
    } else {
      setText(
        "After our walk through the woods, our assessment is that you have good hearing."
      )
    }
  }, [])

  const showResults = () => {
    openInitialPopup(false)
    setTimeout(function () {
      setReady(true)
    }, 500)
  }

  return (
    <div
      className={
        ready ? "birdsong-screen results ready" : "birdsong-screen results"
      }
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Birdsong - Results</title>
        <link
          rel="canonical"
          href="https://beta.hearingbirdsong.com/results/"
        />
      </Helmet>
      <div className="overlay"></div>
      <div className="results_inner">
        <h5>Results</h5>
        <h2>{resultText}</h2>

        <ul className="results-list">
          {results.map((result, i) => {
            let result_text = "Moderate"
            let result_score = 0

            switch (result.result) {
              case 1:
                result_text = "Excellent"
                result_score = 5
                break
              case 2:
                result_text = "Good"
                result_score = 4
                break
              case 3:
                result_text = "Moderate"
                result_score = 3
                break
              case 4:
                result_text = "Fair"
                result_score = 2
                break
              case 5:
                result_text = "Limited"
                result_score = 1
                break
              default:
                result_text = "Moderate"
                break
            }

            return (
              <li key={i}>
                <ResultRow
                  text={result_text}
                  score={result_score}
                  bird={result.bird}
                  sound={result.sound}
                  description={result.description}
                  chapter={result.chapter}
                />
              </li>
            )
          })}
        </ul>
        <div className="resources">
          <h4>Resources</h4>
          <h3>
            To understand more about your hearing, you can find additional
            support from these resources
          </h3>
          <div className="result_buttons">
            <a
              href="https://rnid.org.uk/"
              className="button external"
              target="_blank"
              rel="noreferrer"
            >
              RNID
            </a>
            <a
              href="https://www.hearinglink.org/"
              className="button external"
              target="_blank"
              rel="noreferrer"
            >
              Hearing Link
            </a>
            <a
              href="https://www.nhs.uk/conditions/hearing-tests/"
              className="button external"
              target="_blank"
              rel="noreferrer"
            >
              NHS England
            </a>
          </div>
          <button
            className="button mint finish"
            onClick={() => openPopup(true)}
          >
            Finish
          </button>
        </div>
      </div>
      {popup && (
        <div className="popup">
          <div className="popup_inner">
            <h4>Hearing Birdsong Beta</h4>

            <div>
              <p>
                Thank you for your time! Any thoughts you’d like to share with
                us would be very helpful as we continue to develop this
                resource. We’d appreciate if you would take a few minutes and
                fill out our survey, or you can share any thoughts with us
                directly at{" "}
                <a href="mailto:hello@hearingbirdsong.com">
                  hello@hearingbirdsong.com
                </a>
              </p>
              <div className="popup_buttons">
                <Link
                  to="https://y54iphro75h.typeform.com/to/f4niD9eu"
                  className="button black"
                >
                  Feedback survey
                </Link>
                <Link
                  to="https://beta.hearingbirdsong.com/"
                  className="button black outline"
                >
                  Restart
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {initialPopup && (
        <div
          className={
            pageLoad ? "popup popup-accept loaded" : "popup popup-accept"
          }
        >
          <div className="popup_inner">
            <h4>Hearing Birdsong Beta</h4>
            <div>
              <p>
                Thank you for taking part in our beta. The following results
                have been created based on your responses. As this is a
                prototype in development, please be aware that this should not
                replace a professional hearing test.
              </p>
              <button
                onClick={() => setToggle(!toggle)}
                className={"checkbox " + (toggle ? "checked" : "not-checked")}
              >
                <span></span>
                <p>I understand</p>
              </button>
              <div className="popup_buttons">
                <button
                  onClick={showResults}
                  className="button"
                  disabled={!toggle}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Results
